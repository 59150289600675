import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

import { Event as ExportEvent } from '../../shared/components/model/event';
import { Filter, FilterFields } from '../../shared/components/model/filter';
import { DialogService } from '../../shared/components/services/dialog.service';
import { EventService } from '../../shared/components/services/event.service';
import { SignupStatsService } from '../../shared/components/services/signup-stats.service';

@Component({
  selector: 'app-signup-stats',
  templateUrl: './signup-stats.component.html',
  styleUrls: ['./signup-stats.component.css'],
})
export class SignupStatsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'contractNr',
    'schoolName',
    'schoolYear',
    'signUpYear',
    'signUpId',
    'isInflux',
    'isImported',
    'isDeleted',
    'signUpCreatedAt',
    'modifiedAt',
    'completedAt',
    'actions',
  ];
  hoveredRow: number;

  filter: Filter;

  filtersToInclude: FilterFields = {
    contractNrs: true,
    schoolYear: true,
    signUpYear: true,
    applicationsFrom: true,
    applicationsUntil: true,
    type: false,
    isActive: false,
    isOnboarding: false,
    isOnboardingCompleted: false,
    inProduction: false,
    searchBar: true,
  };

  constructor(
    private signupStatsService: SignupStatsService,
    private _snackBar: MatSnackBar,
    private readonly dialogService: DialogService,
    private router: Router,
    private eventService: EventService,
  ) {}

  ngOnInit(): void {
    this.resetFilter();
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
    this.getSignupStats();
  }

  getSignupStats(): void {
    this.signupStatsService
      .getSignupStatsByFilter(this.filter)
      .pipe(take(1))
      .subscribe((dto) => {
        this.filter.totalItems = dto.totalItems;
        this.filter.currentPage = dto.currentPage;
        this.dataSource.data = dto.signupStats;
      });
  }

  getSignupStatsBySignupId(): void {
    this.signupStatsService
      .getSignupStatsBySignupID(this.filter)
      .pipe(take(1))
      .subscribe((dto) => {
        this.filter.totalItems = dto.totalItems;
        this.filter.currentPage = dto.currentPage;
        this.dataSource.data = dto.signupStats;
      });
  }

  resetFilterAndSearch(): void {
    this.resetFilter();
    this.getSignupStats();
  }

  resetFilter(): void {
    this.filter = {
      totalItems: 0,
      currentPage: 0,
      limit: 0,
      pageSize: 20,
      totalPages: 0,
      orderColumn: 'signUpCreatedAt',
      orderDir: 'desc',
      contractNrs: [],
      schoolYear: null,
      signUpYear: null,
      applicationsFrom: null,
      applicationsUntil: null,
      searchFilterText: '',
      searchableProperties: 'AanmeldNr',
      searchFilterPlaceHolder: '657310cde8603509d0df21b9',
    } as Filter;
  }

  applyFilter(): void {
    this.filter.currentPage = 0;
    this.getSignupStats();
  }

  applySearchText(text: string): void {
    this.resetFilter();
    this.filter.searchFilterText = text;
    this.getSignupStatsBySignupId();
  }

  onPageChange(e: any): PageEvent {
    this.filter.currentPage = e.pageIndex;
    this.filter.pageSize = e.pageSize;
    this.getSignupStats();
    return e;
  }

  onSort(sortInfo: Sort): void {
    this.filter.orderColumn = sortInfo.active;
    this.filter.orderDir = sortInfo.direction;

    this.getSignupStats();
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }

  onDelete(signUpId: string, contractNr: string): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt informatie over de statistiek uit de database te verwijderen. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het AanmeldNr',
        signUpId,
        true,
        'verwijdering',
      )
      .pipe(take(1))
      .subscribe((value) => {
        this.signupStatsService
          .deleteSignupStat(signUpId, contractNr, value.audit)
          .pipe(
            tap({
              next: (response) => {
                if (response.status === 200) {
                  this.showToast(
                    'Statistiek met AanmeldNr ' +
                      signUpId +
                      ' succesvol verwijderd.',
                  );
                }
                this.getSignupStats();
              },
              error: (error) => {
                if (error.status === 409) {
                  this.showToast(
                    'Aanmelding met AanmeldNr ' +
                      signUpId +
                      ' bestaat nog. Statistiek is niet verwijderd.',
                  );
                } else {
                  this.showToast(
                    'Er is iets fout gegaan bij het verwijderen van statistiek met AanmeldNr ' +
                      signUpId,
                  );
                }
              },
            }),
          )
          .subscribe();
      });
  }

  export(signUpId: string, contractNr: string): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt om een aanmelding te exporteren. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het AanmeldNr',
        signUpId,
        false,
        'export',
      )
      .pipe(take(1))
      .subscribe((_) => {
        const event = {
          action: 'EXPORT',
          actionType: 'SIGNUP',
          contractNr: contractNr,
          identifier: signUpId,
          queue: 'export',
          msg: 'handmatig',
        } as ExportEvent;
        this.eventService
          .sendEvent(event)
          .pipe(take(1))
          .subscribe((_) => {
            this.showToast('Export event is verstuurd.');
          });
      });
  }

  onMouseOver(rowIndex: number): void {
    this.hoveredRow = rowIndex;
  }

  onMouseOut(): void {
    this.hoveredRow = null;
  }

  openEditor(row): void {
    this.router.navigate(['/signup/' + row.signUpId]);
  }
}
