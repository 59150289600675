import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Contract } from '../../shared/components/model/contract';
import { Filter, FilterFields } from '../../shared/components/model/filter';
import { ContractService } from '../../shared/components/services/contract.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
})
export class ContractComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource<Contract>();
  displayedColumns: string[] = [
    'contractNr',
    'name',
    'type',
    'isActive',
    'inProduction',
    'dbStatus',
    'kcStatus',
    'supportStatus',
    'actions',
  ];

  filtersToInclude: FilterFields = {
    contractNrs: false,
    schoolYear: false,
    signUpYear: false,
    applicationsFrom: false,
    applicationsUntil: false,
    type: true,
    isActive: true,
    isOnboarding: true,
    isOnboardingCompleted: false,
    inProduction: true,
    searchBar: true,
  };

  filter: Filter;

  llqApUrl: string = environment.llqApUrl;
  llqBpUrl: string = environment.llqBpUrl;

  llqApPort: string = environment.llqApPort;
  llqBpPort: string = environment.llqBpPort;

  ngOnInit() {
    this.resetFilter();
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
    this.getContracts();
  }

  constructor(
    private router: Router,
    private contractService: ContractService,
  ) {}

  getContracts(): void {
    this.contractService
      .getContractsByFilter(this.filter)
      .pipe(take(1))
      .subscribe((dto) => {
        this.filter.totalItems = dto.totalItems;
        this.filter.currentPage = dto.currentPage;
        this.dataSource.data = dto.contracts;
      });
  }

  getContractsBySearchText() {
    this.contractService
      .getContractsBySearchText(this.filter)
      .pipe(take(1))
      .subscribe((dto) => {
        this.filter.totalItems = dto.totalItems;
        this.filter.currentPage = dto.currentPage;
        this.dataSource.data = dto.contracts;
      });
  }

  goToContract(row: any): void {
    if (row === null) {
      this.router.navigate(['/contract/new']);
      return;
    }

    this.router.navigate(['/contract', row.id]);
  }

  goTo(event: any, row: Contract, type: string): void {
    event.stopPropagation();
    window.open(
      location.protocol +
        '//' +
        row.contractNr +
        (type === 'ap' ? this.llqApUrl : this.llqBpUrl) +
        (type === 'ap' ? this.llqApPort : this.llqBpPort),
      '_blank',
    );
  }

  onSort(sortInfo: Sort): void {
    this.filter.orderColumn = sortInfo.active;
    this.filter.orderDir = sortInfo.direction;

    this.getContracts();
  }

  onPageChange(e: any): PageEvent {
    this.filter.currentPage = e.pageIndex;
    this.filter.pageSize = e.pageSize;
    this.getContracts();
    return e;
  }

  applyFilter(): void {
    this.filter.currentPage = 0;
    this.getContracts();
  }

  applyFilterText(text: string): void {
    this.resetFilter();
    this.filter.searchFilterText = text;
    this.getContractsBySearchText();
  }

  resetFilterAndSearch() {
    this.resetFilter();
    this.getContracts();
  }

  resetFilter() {
    this.filter = {
      totalItems: 0,
      currentPage: 0,
      limit: 0,
      pageSize: 15,
      totalPages: 0,
      orderColumn: 'contractNr',
      orderDir: 'desc',
      type: null,
      isActive: null,
      isOnboarding: null,
      isOnboardingCompleted: null,
      inProduction: null,
      searchFilterText: '',
      searchableProperties: 'contractnummer of naam',
      searchFilterPlaceHolder: '1234 of Nieuw Contract',
    } as Filter;
  }
}
