<h2>Contracten</h2>
<app-filter
  [filterFields]="filtersToInclude"
  [filter]="filter"
  (resetFilter)="resetFilterAndSearch()"
  (applyFilter)="applyFilter()"
  (searchText)="applyFilterText($event)"
></app-filter>
<br />
<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table mat-table matSort aria-label="Selecteer" [dataSource]="dataSource">
        <ng-container matColumnDef="contractNr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.contractNr }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Actief</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isActive | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="inProduction">
          <th mat-header-cell *matHeaderCellDef>In productie</th>
          <td mat-cell *matCellDef="let element">
            {{ element.inProduction | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dbStatus">
          <th mat-header-cell *matHeaderCellDef>Database</th>
          <td mat-cell *matCellDef="let element">{{ element.dbStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="kcStatus">
          <th mat-header-cell *matHeaderCellDef>KeyCloak</th>
          <td mat-cell *matCellDef="let element">{{ element.kcStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="supportStatus">
          <th mat-header-cell *matHeaderCellDef>Support</th>
          <td mat-cell *matCellDef="let element">
            {{ element.supportStatus }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acties</th>
          <td mat-cell *matCellDef="let element">
            <button mat-mini-fab (click)="goTo($event, element, 'ap')">
              <mat-icon>school</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="primary"
              (click)="goTo($event, element, 'bp')"
            >
              <mat-icon>website</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          (click)="goToContract(row)"
          *matRowDef="let row; columns: displayedColumns"
          class="detail-row"
        ></tr>
      </table>
      <mat-paginator
        [length]="filter.totalItems"
        [pageSize]="filter.pageSize"
        [pageIndex]="filter.currentPage"
        [pageSizeOptions]="[10, 15, 20, 25]"
        (page)="pageEvent = onPageChange($event)"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" (click)="goToContract(null)">
      <mat-icon>add</mat-icon> Toevoegen
    </button>
  </mat-card-actions>
</mat-card>
