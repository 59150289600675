<h1 mat-dialog-title>Bevestigen</h1>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-raised-button (click)="cancel()">
    <mat-icon>clear</mat-icon> Nee
  </button>
  <button mat-raised-button color="primary" (click)="confirm()">
    <mat-icon>check</mat-icon> Ja
  </button>
</mat-dialog-actions>
