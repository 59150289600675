<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mdc-layout-grid mdc-layout-grid--align-left">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['contractNrs']"
        >
          <mat-form-field class="full-width">
            <mat-label>Contract</mat-label>
            <mat-select [(ngModel)]="filter.contractNrs" [multiple]="true">
              <mat-option
                *ngFor="let contract of contracts"
                [value]="contract.contractNr"
              >
                {{ contract.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['schoolYear']"
        >
          <mat-form-field class="full-width">
            <mat-label>Schooljaar</mat-label>
            <mat-select [(ngModel)]="filter.schoolYear">
              <mat-option [value]="'2025/2026'"> 2025/2026 </mat-option>
              <mat-option [value]="'2024/2025'"> 2024/2025 </mat-option>
              <mat-option [value]="'2023/2024'"> 2023/2024 </mat-option>
              <mat-option [value]="'2022/2023'"> 2022/2023 </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['signUpYear']"
        >
          <mat-form-field class="full-width">
            <mat-label>Aanmeldjaar</mat-label>
            <mat-select [(ngModel)]="filter.signUpYear">
              <mat-option [value]="'2025/2026'"> 2025/2026 </mat-option>
              <mat-option [value]="'2024/2025'"> 2024/2025 </mat-option>
              <mat-option [value]="'2023/2024'"> 2023/2024 </mat-option>
              <mat-option [value]="'2022/2023'"> 2022/2023 </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['applicationsFrom']"
        >
          <mat-form-field class="full-width">
            <mat-label>Aanmeldingen vanaf</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="filter.applicationsFrom"
              placeholder="DD-MM-YYYY"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['applicationsUntil']"
        >
          <mat-form-field class="full-width">
            <mat-label>Aanmeldingen tot</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="filter.applicationsUntil"
              placeholder="DD-MM-YYYY"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['type']"
        >
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="filter.type">
              <mat-option *ngFor="let type of contractTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['isActive']"
        >
          <mat-form-field class="full-width">
            <mat-label>Is actief</mat-label>
            <mat-select [(ngModel)]="filter.isActive">
              <mat-option [value]="true"> Ja </mat-option>
              <mat-option [value]="false"> Nee </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['isOnboarding']"
        >
          <mat-form-field class="full-width">
            <mat-label>Is onboarding</mat-label>
            <mat-select [(ngModel)]="filter.isOnboarding">
              <mat-option [value]="true"> Ja </mat-option>
              <mat-option [value]="false"> Nee </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['isOnboardingCompleted']"
        >
          <mat-form-field class="full-width">
            <mat-label>Onboarding compleet</mat-label>
            <mat-select [(ngModel)]="filter.isOnboardingCompleted">
              <mat-option [value]="true"> Ja </mat-option>
              <mat-option [value]="false"> Nee </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2"
          *ngIf="filterFields['inProduction']"
        >
          <mat-form-field class="full-width">
            <mat-label>Is in productie</mat-label>
            <mat-select [(ngModel)]="filter.inProduction">
              <mat-option [value]="true"> Ja </mat-option>
              <mat-option [value]="false"> Nee </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
          <button mat-raised-button color="primary" (click)="sendFilter()">
            <mat-icon>filter_alt</mat-icon> Pas filter toe
          </button>
          <button mat-raised-button (click)="onResetFilter()" class="mr-5">
            <mat-icon>search_off</mat-icon> Reset filter
          </button>
        </div>
        <ng-container *ngIf="filterFields.searchBar">
          <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-10">
            <mat-form-field class="full-width">
              <mat-label><mat-icon>search</mat-icon>Zoeken</mat-label>
              <input
                matInput
                type="input"
                [(ngModel)]="searchTextString"
                (keydown.enter)="filterByText()"
                [placeholder]="
                  filter.searchFilterPlaceHolder
                    ? 'Ex. ' + filter.searchFilterPlaceHolder
                    : ''
                "
              />
              <mat-hint align="start">
                Filters worden niet meegenomen in de zoek functie, zoeken op
                {{ filter.searchableProperties }}
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
            <button mat-raised-button color="primary" (click)="filterByText()">
              <mat-icon>search</mat-icon>
              Zoeken
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
