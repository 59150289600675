import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  Address,
  ContactPerson,
  Contract,
  ContractDTO,
} from '../model/contract';
import { Filter } from '../model/filter';
import { LasInfo } from '../model/las-info';
import {
  SchoolConfigurationDTO,
  SchoolConfigurationLasUpdate,
} from '../model/school-configuration';
import {Url} from "../../../modules/contract/contract-edit/contact/url";

@Injectable()
export class ContractService {
  contractUrl = '/api/contract/';

  constructor(private http: HttpClient) {}

  getContracts() {
    return this.http.get<Contract[]>(this.contractUrl);
  }

  getContract(contractId: string): Observable<Contract> {
    return this.http.get<Contract>(this.contractUrl + contractId);
  }

  getContractsByFilter(filter: Filter) {
    return this.http.post<ContractDTO>(
      this.contractUrl.concat('filter'),
      filter,
    );
  }

  getContractsBySearchText(filter: Filter) {
    return this.http.post<ContractDTO>(
      this.contractUrl.concat('search'),
      filter,
    );
  }

  saveContract(frmData): Observable<Contract> {
    let contract: Contract = {
      id: frmData.id,
      name: frmData.name,
      dvo: frmData.dvo,
      remarks: frmData.remarks,
      isActive: frmData.isActive,
      contractStartDate: frmData.contractStartDate,
      hostName: frmData.hostName,
      isOnboarding: frmData.isOnboarding,
      onboarding: { numberOfTiles: frmData.numberOfTiles },
      forecast: frmData.forecast,
      poolList: frmData.poolList,
    } as unknown as Contract;

    return this.http.post<Contract>(this.contractUrl + contract.id, contract);
  }
  addContract(contract: Contract): Observable<Contract> {
    return this.http.post<Contract>(this.contractUrl, contract);
  }

  addContactPerson(
    contractID: string,
    contactPerson: ContactPerson,
  ): Observable<Contract> {
    return this.http.post<Contract>(
      this.contractUrl + contractID + '/contactperson',
      contactPerson,
    );
  }

  updateContactPerson(contractID: string, contactPerson: ContactPerson) {
    return this.http.put<Contract>(
      this.contractUrl + contractID + '/contactperson/' + contactPerson.id,
      contactPerson,
    );
  }

  deleteContactPerson(contractID: string, contactId: string) {
    return this.http.delete<Contract>(
      this.contractUrl + contractID + '/contactperson/' + contactId,
    );
  }

  addAddress(contractID: string, address: Address) {
    return this.http.post<Contract>(
      this.contractUrl + contractID + '/address',
      address,
    );
  }

  updateAddress(contractID: string, address: Address) {
    return this.http.put<Contract>(
      this.contractUrl + contractID + '/address/' + address.id,
      address,
    );
  }

  deleteAddress(contractID: string, address: Address) {
    return this.http.delete<Contract>(
      this.contractUrl + contractID + '/address/' + address.id,
    );
  }

  deleteContract(contract: Contract) {
    return this.http.delete<Contract>(this.contractUrl + contract.id);
  }

  checkDb(contract: Contract) {
    return this.http.post<Contract>(
      this.contractUrl + contract.id + '/checkdb',
      null,
    );
  }

  checkKc(contract: Contract) {
    return this.http.post<Contract>(
      this.contractUrl + contract.id + '/checkkc',
      null,
    );
  }

  getKcZip(contract: Contract): Observable<Blob> {
    return this.http.get(this.contractUrl + contract.id + '/kczip', {
      responseType: 'blob',
    });
  }

  checkSupport(contract: Contract) {
    return this.http.post<Contract>(
      this.contractUrl + contract.id + '/checksupport',
      null,
    );
  }

  removeSupport(contract: Contract) {
    return this.http.post<Contract>(
      this.contractUrl + contract.id + '/removesupport',
      null,
    );
  }

  checkRoles(contract: Contract) {
    return this.http.post<Contract>(
      this.contractUrl + contract.id + '/checkroles',
      null,
    );
  }

  addBackendUser(contractID: string, id) {
    return this.http.post<boolean>(
      this.contractUrl + contractID + '/contactperson/' + id + '/kc',
      null,
    );
  }

  addSupportUser(contractID: string, id) {
    return this.http.post<boolean>(
      this.contractUrl + contractID + '/contactperson/' + id + '/support',
      null,
    );
  }

  impersonate(contractId: string) {
    return this.http.post<Url>(
      this.contractUrl + contractId + '/impersonate',
      null,
    );
  }

  removeSupportUser(contractID: string, id) {
    return this.http.delete<boolean>(
      this.contractUrl + contractID + '/contactperson/' + id + '/support',
    );
  }

  updateContractForProduction(contractID: string): Observable<number> {
    return this.http
      .post(this.contractUrl + contractID + '/production', null, {
        observe: 'response',
        responseType: "text"
      })
      .pipe(map((response) => response.status));
  }

  getLasInfo(): Observable<LasInfo[]> {
    return this.http.get<LasInfo[]>(this.contractUrl + 'lasinfo');
  }

  updateDbLasInfo(contractID: string) {
    return this.http.post(this.contractUrl + 'lasinfo', contractID);
  }

  getAllSchoolConfigurations(
    contractId: string,
  ): Observable<SchoolConfigurationDTO[]> {
    return this.http.get<SchoolConfigurationDTO[]>(
      this.contractUrl + contractId + '/schoolconfiguration',
    );
  }

  updateSchoolConfigurationLasInfo(
    lasUpdate: SchoolConfigurationLasUpdate,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.contractUrl + 'schoolconfiguration',
      lasUpdate,
    );
  }
}
